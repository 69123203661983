import $ from 'jquery';
import 'bootstrap';
import 'popper.js';

import './sticky';

window.$ = window.jQuery = $;

$(function () {
    //mobile nav controller
    $('.mobile-nav-trigger').on('click', function () {
        $(this).toggleClass('active');
        $('nav.desktop-nav').toggleClass('mobile-menu-open');
    });
    
    $('[name="state-select-header-select"]').on('change', function(){
        var x = $(this).val();
        x = x.replace(/\s+/g, '-').toLowerCase();
        window.location = x;
    });

    $('.indiv-accordion .h').on('click', function(){
        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $(this).next().slideUp();
            $(this).find('i').removeClass('fa-minus').addClass('fa-plus');
        }
        else {
            //setup some vars to make life easy peezy 
            var $thisH = $(this);
            var $thisB = $(this).next();
            var $thisI = $(this).find('i');

            //close all open accords
            $('.indiv-accordion .body').slideUp();
            $('.indiv-accordion .h').removeClass('open');
            $('.indiv-accordion .h i').removeClass('fa-minus').addClass('fa-plus');

            //open the clicked accord
            $thisB.slideDown();
            $thisH.addClass('open');
            $thisI.removeClass('fa-plus').addClass('fa-minus');
        }
    });

    $('.indiv-accordion .h').eq(0).trigger('click');


});